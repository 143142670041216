import { Container, Row, Col } from "react-bootstrap";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { useEffect, useState } from "react";
import axios from "axios";

export const Projects = () => {
  const [sliderData, setSliderData] = useState();
  const getSliderData = async () => {
    try {
      const response = await axios.get(
        `https://api.artcubestudio.in/projExp`,
        null
      );
      if (response) {
        console.log("response.data.DATA : ", response.data.DATA);
        const d = response.data.DATA;
        setSliderData(d[0]);
      }
    } catch (error) {
      console.log("error ", JSON.stringify(error));
    }
  };

  useEffect(() => {
    getSliderData();
  }, []);

  const projects = [
    {
      title: "Business Startup",
    },
    {
      title: "Residential",
    },
    {
      title: "Retail",
    },
    {
      title: "Hospitality",
    },
    {
      title: "Corporate Offices",
    },
    {
      title: "Commercial",
    },
    {
      title: "Educational",
    },
    {
      title: "Healthcare",
    },
    {
      title: "Culture Centers",
    },
    {
      title: "Industrial",
    },
  ];

  const aboutExp =
    "ArtCube was founded in 2010. It is a comprehensive and specialised Design Development & Documentation resource for Interior designers working in the hospitality sector. We combine the talents and experience of professional designers, architects and support personnel along with state of art technology to deliver truly integrated interior design Documentation services. Our Team works as an extended arm ofthe Client’s Studio.";

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <div className="skill_title">PROJECT EXPERIENCE</div>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "justify",
                    }}
                  >
                    <Col md={6} sm={12}>
                      <p
                        style={{
                          width: "80%",
                          alignSelf: "center",
                          textAlign: "justify",
                          color: "#000000",
                        }}
                      >
                        {sliderData ? sliderData.main_content : aboutExp}
                      </p>
                    </Col>
                    <Col md={6} sm={12}>
                      <img
                        src={
                          sliderData
                            ? sliderData?.image
                            : require("../assets/img/projects.png")
                        }
                        alt={"projects"}
                      />
                    </Col>
                  </Row>

                  <div className="flexContainer">
                    {sliderData
                      ? sliderData.keywords?.map((item, index) => {
                          return (
                            <div className="flexBox" key={index}>
                              {item}
                            </div>
                          );
                        })
                      : projects.map((item, index) => {
                          return (
                            <div className="flexBox" key={index}>
                              {item.title}
                            </div>
                          );
                        })}
                  </div>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="bg" />
    </section>
  );
};
